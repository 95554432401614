
import { Component, Prop, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
import { User } from '@/models/index'

@Component({
  name: 'UserCard',
  components: {
  }
})
export default class extends Vue {
  @Prop({ required: false }) private user!: User

  userStore: any = useUserStore()

  get user_() {
    return this.user || this.userStore.model
  }

  get roles() {
    return this.userStore.roles
  }
}
