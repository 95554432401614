
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
import Account from './components/Account.vue'
import UserCard from './components/UserCard.vue'

@Component({
  name: 'Profile',
  components: {
    Account,
    UserCard
  }
})
export default class extends Vue {
  userStore: any = useUserStore()
  private activeTab = 'account'

  get user() {
    return this.userStore.model
  }
}
